import React from "react";
import { graphql } from 'gatsby'
import { Container, Row, Col } from "react-bootstrap";
import loadable from "@loadable/component";
import Layout from "../components/layout";

const ValuationCard = loadable(() => import("../components/ValuationCard/ValuationCard"));

const Valuation = (props) => {
    const { wpPage: { ACF_Cards: data}} = props.data;
    return (
        <Layout>
            <div className="layout-padding-top"></div>

            <div className="valuation-wrapper">
                <Container>
                    <Row className="d-flex justify-content-center">
                        <Col xl={8}>
                            <Row>
                                {data.card.map((card) => {
                                    return(
                                        <Col md={6}>
                                            <ValuationCard 
                                                img={card.ctaImage.sourceUrl}
                                                text={card.ctaTitle}
                                                desc={card.ctaDescription}
                                                btnlabel={card.ctaLabel}
                                                btnlink={card.ctaLink.slug}
                                            />
                                        </Col>
                                    )
                                })}
                            </Row>
                        </Col>
                    </Row>
                </Container>
            </div>
        </Layout>
    )
}

export const query = graphql`
  query ($page_id: String) {
    wpPage(id: {eq: $page_id}) {
        id
        title
        ACF_Cards {
            card {
                ctaDescription
                ctaLabel
                ctaTitle
                ctaLink {
                ... on WpPage {
                    id
                    slug
                }
                }
                ctaImage {
                id
                caption
                sourceUrl
                }
            }
        }
    }
  }  
`

export default Valuation